import React, { useState, useEffect } from 'react';
import ExpandCard from './ExpandCard';
import Email from '../Email';
import { defaultContact } from '../../utils/contact';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';
import '../../pages/page-styles.scss';

const Body = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulResources {
        edges {
          node {
            content {
              raw
            }
            title
          }
        }
      }
    }
  `);

  const { edges } = data.allContentfulResources;
  const findContent = section =>
    edges.filter(content => content.node.title === section)[0].node.content.raw;
  const [idx, setId] = useState(null);

  useEffect(() => {
    setId(location?.replace(/%20/g, ' '));
  }, [location]);

  return (
    <section className="section">
      <ExpandCard title={'Teens'} idx={idx}>
        <>{renderRichText(JSON.parse(findContent('Teens')))}</>
      </ExpandCard>
      <ExpandCard title={'Family'} idx={idx}>
        <>{renderRichText(JSON.parse(findContent('Family')))}</>
      </ExpandCard>
      <ExpandCard title={'Cultural Competent Education and Training'} idx={idx}>
        <>
          {renderRichText(
            JSON.parse(findContent('Cultural Competent Education and Training'))
          )}
        </>
      </ExpandCard>
      <ExpandCard title={'National Education and Training'} idx={idx}>
        <>
          {renderRichText(
            JSON.parse(findContent('National Education and Training'))
          )}
        </>
      </ExpandCard>
      <ExpandCard
        title={'Massachusetts and New England Education and Training'}
        idx={idx}
      >
        <>
          {renderRichText(
            JSON.parse(
              findContent(
                'Massachusetts and New England Education and Training'
              )
            )
          )}
        </>
      </ExpandCard>
      <ExpandCard title={'Massachusetts Resources'} idx={idx}>
        <>
          {renderRichText(JSON.parse(findContent('Massachusetts Resources')))}
        </>
      </ExpandCard>
      <ExpandCard title={'Land Acknowledgement'} idx={idx}>
        <>{renderRichText(JSON.parse(findContent('Land Acknowledgement')))}</>
      </ExpandCard>
      <ExpandCard title={'contact us'}>
        <p>
          Email: <Email />
        </p>
        <p>Phone: {defaultContact.phone}</p>
      </ExpandCard>
    </section>
  );
};

export default Body;
