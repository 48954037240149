import React, { useState, useEffect } from 'react';
import './styles.scss';
import '../../pages/page-styles.scss';

const ExpandCard = ({ title, children, idx }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleCardState = () => setExpanded(prev => !prev);
  useEffect(() => {
    return title === idx ? setExpanded(true) : setExpanded(false);
  }, [idx, title]);

  return (
    <div id={title} className="anchor">
      <div className="column">
        <div className={'card ' + (expanded ? 'expanded' : 'not-expanded')}>
          <header
            aria-hidden="true"
            className="card-header p-2"
            onClick={toggleCardState}
          >
            <h4 className="card-header-title is-size-4 is-uppercase">
              {title}
            </h4>
            <div className="card-header-icon">
              <span className="icon">
                <i className="fa fa-angle-up"></i>
              </span>
            </div>
          </header>
          <div className={`card-content ${!expanded && 'is-hidden'}`}>
            <div className="content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandCard;
