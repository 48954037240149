import React from 'react';
import Layout from '../components/layout';
import Body from '../components/resources/index';

const headerText = 'RESOURCES';

const Resources = ({ location }) => {
  const { href } = location;
  const idx = href && href.split('#')[1];

  return (
    <Layout
      headerText={headerText}
      heroFont="has-text-white"
      heroBg="resources-bg"
    >
      <Body location={idx} />
    </Layout>
  );
};

export default Resources;
